body{
    margin: 0;
    padding: 0;
    font-weight: 300;
    font-family: 'Baloo Bhaina';
}

html{
    scroll-behavior: smooth;
}
header{
    position : fixed;
    width: 100%;
    height: 8vh;
    box-shadow: 1px 1px 12px #aaa;

    z-index: 10;
    background-color: white;
}
@media(max-width: 768px){
    header{
        height: 10vh;
    }
}
.auto{
    margin: 0 auto;
    width: 70%;
}
header .menu{
    margin : 0 ;
    padding: 0;
    list-style-type: none;
}

header .menu li{
    display: inline-block;
    width: 25vh;
    height: 8vh;
    transition: 500ms;
} 
header .menu li a {
    width: 25vh;
    display: block;
    text-decoration: none;
    height: 8vh;
    color: black;
    font-family: 'Baloo Bhaina';
    text-align: center;
    line-height: 9.5vh;
    font-size: 2vh;
    transition: 500ms;

}
header .menu li a:hover{
    background-color:#26C4EC;
    color: white;
}

@media(max-width: 768px){
    
    header{
        height: 10vh;
    }

    header .menu li{
        width: 90%;
        height : 12vh;
        margin: 0 3.5vh;
    }

    header .menu li span img{
        height : 5vh;
        width:3vh;
    }

    header .menu li a {
        height : 10vh;
        line-height: 13vh;
        font-size: 4vh;
        margin: 0 1vh 0 0;
    }
    header .menu li:nth-last-of-type(1){
        display: none;
    }

    header .menu li a:hover{
        background-color: transparent;
        color: black;
    }
    
}

.home{
    position: relative;
    top: 0;
    width: 100%;
    height:100vh;
    background-color: #26C4EC;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

#particle{
    position: absolute;
    top: 0;
    margin: 0 auto;
    width: 100%;
    height: 100vh;
    z-index:5;

} 
button:focus{
    outline: none;
    border: none;
}

button:hover{
    cursor: pointer

}
button:active{
    transform: scale(0.98);
}

#home_container{
    position: relative;
    top: 30vh;
    margin: 0 auto;
    width: 70%;
    z-index:5;
}

#continus{
    position: relative;
    top: 30vh;
    display: none   ;
    margin: 0 auto;
    width: 70vh;
    z-index:5;
}

#continus img{
    width: 20vh;
}

#continus h1 {
    text-align: center;
    color: white;
    font-size: 7vh;
    font-family: 'Baloo Bhaina';
    margin: 0;
}
#continus p{
    text-align: center;
    color: white;
}


#home_container img{
    width: 15vh;
}

#home_container h1{
    text-align: center;
    color: white;
    font-size: 10vh;
    font-family: 'Baloo Bhaina';
    margin: 1vh 0;
    font-weight: 200;
}

#home_container p{
    text-align: center;
    color: white;
}
#rondom{
    position: relative;
    top: 40%;
    margin: 0 auto;
    width: 70vh;
    z-index:5;
    display : none;

}
#reflexe{
    position: relative;
    top: 35%;
    margin: 0 auto;
    width: 80vh;
    z-index:5;
    display : none;
}

#time{
    position: relative;
    top: 27%;
    margin: 0 auto;
    width: 70vh;
    z-index:5;
    display : none;
}
@media(max-width:768px){
    #rondom{
        top: 40%;
        width:90%;
    }
    #home_container {
        width:90%;
        top:25%;
    }

    #home_container h1 {
        text-align: center;
        color: white;
        font-size: 6vh;
        font-family: 'Baloo Bhaina';
        margin: 5vh 0 0 0;
    }

    #reflexe{
        width:95%;
    }

    #time{
        width:95%;
    }
}


#form ul{
    margin : 0;
    padding: 0;
}

#form ul li{
    display: inline-block;
    width: 3vh;
    height: 3vh;
    background-color: white;
    color: white;margin: 0 0.3vh;
    border-radius: 3vh;
}

#rondom ul{
    margin : 0;
    padding: 0;
    list-style-type: none;
}

#rondom ul li{
    display: inline-block;
    width: 3vh;
    height: 3vh;
    background-color: white;
    color: white;margin: 0 0.3vh;
    border-radius: 3vh;
}

#reflexe ul{
    margin : 0;
    padding: 0;
    list-style-type: none;
}

#reflexe ul li{
    display: inline-block;
    width: 3vh;
    height: 3vh;
    background-color: white;
    color: white;margin: 0 0.3vh;
    border-radius: 3vh;
}

#tot{
    position: relative;
    top: 30%;
    margin: 0 auto;
    width: 70vh;
    z-index:5;
    display : none;
}

#tot img{
    width: 15vh;
    margin: 0 0 5vh 0;
}
@media(max-width:768px){
    #tot{
        width:95%;
    }
}

#tot ul{
    margin : 0  ;
    padding: 0;
    list-style-type: none;

}

#tot ul li{
    display: inline-block;
    width: 3vh;
    height: 3vh;
    background-color: white;
    color: white;margin: 0 0.3vh;
    border-radius: 3vh;
}

#tot h1,p{
    color: white;
}

#rondom .buttom{
    top:50vh;
    position: absolute;
    margin: 0 auto;
    left: 25vh;
    color: white
}
#rondom img{
    width: 20vh;

}
 
#reflexe .buttom{
    top:50vh;
    position: absolute;
    margin: 0 auto;
    left: 25vh;
    color: white
}
#reflexe img{
    width: 20vh;
}

#form img{
    width: 20vh;
}

#form h1{
    font-size: 5vh;
    color: white;
}

#form p{
    color: white;
    text-align: center;
    font-size: 40vh;
}

#chiffre:focus{
    outline: none;
    border: none;
}

#rondom h1{
    margin:0;padding:0;
    color: white;
    font-size: 5vh;
    font-family: 'Baloo Bhaina';
    text-align:center;

}

#rondom p{
    color: white;
    text-align: center;
    font-size: 8vh;
    font-family: sans-serif;
    font-weight: 300;
    margin: 4vh 0 ;
    padding: 0;
}
#rondom b{
    color: white;
    font-size:8vh;
}

#reflexe h1{
    margin:0;padding:0;
    color: white;
    font-size: 5vh;
    font-family: 'Baloo Bhaina';
    text-align:center;

}

#time img{
    width: 15vh;
    margin:0 0 5vh;
    padding:0;
    color: white;
}

#time h1{
    margin:0;padding:0;
    color: white;
    font-size: 5vh;
    font-family: 'Baloo Bhaina';
    text-align:center;
}
#reflexe p{
    color: white;
    text-align: center;
    font-size: 2vh;
    font-family: sans-serif;
    font-weight: 300;
    margin: 4vh 0;
    padding: 0;
}

#time p{
    color: white;
    text-align: center;
    font-size: 2vh;
    font-weight: 300;
    margin: 4vh 0;
    padding: 0;
}
#reflexe b{
    color: white;
    font-size:8vh;
}

#time b{
    color: white;
    font-size:8vh;
}
.io{
    width: 15vh;
    height: 1vh;
    background-color: rgba(0, 0, 0, 0.356);
    overflow: hidden;
    margin : 5vh auto;
}

.iop{
    width: 15vh;
    height: 1vh;
    background-color: white;
    animation : slide 3000ms;

}

@media(max-width:768px){
    #reflexe p{
        margin: 0;
    }
    .iop{
        width: 15.5vh;
    }
}
@keyframes slide{
    0%{
        transform: translateX(0vh);
    }
    100%{
        transform: translateX(-15vh);
    }
}
#form{
    position: relative;
    margin: 0 auto;
    width: 70vh;
    z-index:5;
    width:40%;
    top:40%;
    display : none;
}
#form button{
    cursor: pointer;
    width: 25vh;
    height: 5vh;
    border-radius: 3vh;
    margin: 1vh 0;
    border:none;
    background-color: orange;
    color: white;
}

@media(max-width:768px){
    #form{
        width:90%;
        top: 40vh;
    }
    #form button{
        width:70%;
        height: 7vh;
        margin:0 ;
        border-radius: 4vh;
        font-size:3vh;
    }
}
#form p{
    margin:0;padding:0;
    text-align: center;
    font-size: 3vh;
    color: white;
}
#form input{
    border-radius: 4vh;
    width: 100%;
    height: 8vh;
    border: none;
    margin :1vh;
    background-color: rgba(0, 0, 0, 0.2);
}
#form input[placeholder]{
    text-align: center;
    font-size: 4vh;
    color: white;
}
#error{
    display : none;
    position: relative;
    top: 30vh;
    margin: 0 auto;
    width: 70vh;
    z-index:5;
    color: white;
}
#resultat{
    display : none;
    position: relative;
    top: 30%;
    margin: 0 auto;
    width: 60vh;
    z-index:5;
    color: white;
    padding: 0;
}
#error button{
    cursor: pointer;
    width: 25vh;
    height: 5vh;
    border-radius: 3vh;
    margin: 1vh 2vh;
    border:none;
    background-color: orange;
    color: white;

}

.btn-scroll{
    width: 30vh;
    height: 5vh;
    border: 1px solid white;
    border-radius: 3vh;
    cursor: pointer;
    background-color: transparent;
    color:white;
    margin: 60vh auto;

}
.container{
    width: 105vh;
    margin: 0 auto;
    padding: 0;
}

@media(max-width:768px){

    .container{
        width: 90%;
        margin: 0 auto;
        padding:0;
    }
    #error{
        width: 95%;
    }

    #error button{
        width: 45%;
        height: 7vh;
        margin: 20vh 1vh;
        border:none;
        border-radius:7vh;
        font-size:2vh;
        font-family: 'Baloo Bhaina';
        font-weight: 300;
    }
    #resultat{
        width :95%;
    }
    #resultat button{
        width: 45%;
        height: 7vh;
        margin: 10vh 1vh;
        border:none;
        border-radius:7vh;
        font-size:2vh;
        font-family: 'Baloo Bhaina';
        font-weight: 300;
    }

    #resultat ul li{
        width:45%;
        font-size:3vh;
        margin:0;
    }
    #resultat ul li:nth-of-type(1){
        text-align:end;
    }

    #resultat b{
        font-size: 15vh;
        margin: 0;padding:0;
    }

    #resultat p{
        margin: 0;padding:0;
    }
}

.test{
    width: 33vh;
    height: 35vh;
    box-shadow: 1px 1px 12px #aaa;
    margin: 1vh 1vh ;
    display: inline-table;
    border-radius: 1vh;
    transition: 500ms;
    background-color: white;
}


.test:hover{
    transform: translateY(-5px);
    box-shadow: 1px 1px 12px rgba(85, 85, 85, 0.8);
}

@media(max-width:768px){
    .test{
        width: 100%;
        margin:1vh auto
    }
}

.red{
    border-top: 1vh solid red;
    color:red;
}
.violet{
    border-top: 1vh solid violet;
    color:violet;

}
.yellow{
    border-top: 1vh solid orange;
    color:orange;
}

.green{
    border-top: 1vh solid rgba(0, 128, 0, 0.756);
    color:rgba(0, 128, 0, 0.756);
}
.marron{
    border-top: 1vh solid rgba(128, 0, 0, 0.822);
    color:rgba(128, 0, 0, 0.863);
}
.blue{
    border-top: 1vh solid  deepskyblue;
    color: deepskyblue;
}
.test h1{
    margin: 3vh 0 ;
    padding:0;
    text-align: center;
    font-size: 3vh;
    font-family:'Baloo Bhaina';
    font-weight: 300;

}
.test img{
    width:12vh;
    margin: 3vh 0  0 0;
}
.test p{
    color: #aaa;
    margin: 1vh 0;
    width: 90%;
    margin: 0px auto;
    font-family: 'Baloo Paaji';
    font-weight: 300;
    font-size:1.7vh;
}
.up{
    animation: up 1200ms;
}
@keyframes up {
    0%{
        opacity: 0;
        transform: translateY(50px);
    }
    10%{
        opacity: 0;
        transform: translateY(50px);
    }
    100%{
        opacity: 1;
        transform: translateY(0px);
    }
}

.success{
    animation : success 700ms;
}

@keyframes success{
    0%{
        background-color: #26C4EC;
    }
    50%{
        background-color: green;
    }
    100%{
        background-color: #26C4EC;
    }
}

.error{
    animation : error 700ms;
}

@keyframes error{
    0%{
        background-color: red;
    }
    50%{
        background-color: royalblue;
    }
    100%{
        background-color: red;
    }
}

.opacity{
    animation : opacity 1500ms infinite;
    opacity: 1;
}

#rondom button{
    cursor: pointer;
    width: 25vh;
    height: 5vh;
    border-radius: 3vh;
    margin: 1vh 0;
    border:none;
    background-color: orange;
    color: white;
}

@keyframes opacity{
    0%{
        opacity: 1;
    }
    50%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
.pointed{
    cursor: pointer;
}

#befor{
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    display: none;
    z-index: 8;
}

.count{
    float: right;
    margin: -8.5vh auto;
    padding: 0;
    list-style-type: none;
}
.count li{
    float: left;
    width: 20vh;
    height: 8.5vh;
    top: 0;
}

@media(max-width: 768px){
    .count li:nth-last-of-type(1){
        display: none;
    }

    .count li:nth-last-of-type(2){
        display: none;
    }
}
.count li a:hover{
    background-color:#26C4EC;
    color: white;
}
.count li a{
    text-align: center;
    display: block;
    width: 20vh;
    color: black;
    height: 8.5vh;
    line-height: 10vh;
    text-decoration: none;
    font-size: 2vh;
    transition: 500ms;
    font-family: 'Baloo Bhaina';
}

#response{
    margin : 0vh auto;
    top: 20vh;
    position: absolute;
    width: 30%;
    padding: 0;
    display: none;

}

.login_img{
    width: 15vh;
    height: 15vh;
    border-radius: 15vh;

}

#btn{
    margin : 0vh auto;
    top: 30vh;
    position: absolute;
    width: 60%;
    padding: 0;
}

.cage{
    width: 17.324vh;
    height: 17.334vh;
    background-color: rgba(0, 0, 0, 0.5 );
    margin: 0 1vh 1vh 0;
    float: left;
    border-radius:1vh;
    color: white;
    perspective: 1000px;
    transform-style: preserve-3d;
}

.ios{
    width:80%;
    height:6vh;
    margin: 0 auto;

}
@media(max-width:768px){
.ios{
    width:100%;
}
}
.cage:hover{
    cursor: pointer;
}

.animate_up{
    animation: ok 1200ms ease-in;
}
@keyframes ok{
    0%{
        transform: rotateX(0deg);
    }
    30%{
        transform: rotateX(180deg);
        background-color: white;
    }
    70%{
        transform: rotateX(180deg);
        background-color: white;
    }
    100%{
        transform: rotateX(0deg)
    }
}


.animate_op{
    animation: op 850ms;
    background-color: white;

}
@keyframes op{
    0%{
        transform: rotateX(0deg);
    }
    40%{
        transform: rotateX(180deg);
        background-color: white;
    }
    100%{
        transform: rotateX(180deg);
        background-color: white;
    }
}



.animate_on{
    animation: on 850ms;
    background-color: rgba(0, 0, 0, 0.8);

}
@keyframes on{
    0%{
        transform: rotateX(0deg);
    }
    40%{
        transform: rotateX(180deg);
        background-color: rgba(0, 0, 0, 0.8);
    }
    100%{
        transform: rotateX(180deg);
        background-color: rgba(0, 0, 0, 0.8);
    }
}
.op{
    animation: it 850ms;
    background-color: rgba(0, 0, 0, 0.5);
}

@keyframes it{
    0%{
        transform: rotateX(0deg);
    }
    40%{
        transform: rotateX(180deg);
        background-color: rgba(0, 0, 0, 0.5);
    }
    100%{
        transform: rotateX(180deg);
        background-color: rgba(0, 0, 0, 0.5);
    }
}
.content-head{
    width: auto;
    height: 5vh;
}

.head{
    float: left;
    color: white;
    font-size: 3vh;
    width:50%;
    text-align: center;
}

@media(max-width:768px){
    .head{
        margin:0;
        padding:0;
        width: 50%;
        height:10vh;
    }
}
.items{
    padding: 2px 1px;
    width: auto;
    height: 4vh;
    color:black;
    margin: 0;
    font-size: 2.5vh;
    text-align: center;
}

.left{
    float: left;
    text-align: center;
    margin: 0 2vh;
}

@media(max-width:768px){
    .left{
        margin: 0;
    }
}

.opp{
    animation: dactylo 700ms infinite;
}
@keyframes dactylo{
    0%{
        border-left: 1.5px solid transparent;

    }
    50%{
        border-left: 1.5px solid black;
    }
    100%{
        border-left: 1.5px solid transparent;
    }
}

#roum{
    display : none;
    position: relative;
    top: 33%;
    margin: 0 auto;
    width: 60vh;
    z-index:5;
    color: white;
    padding: 0;
}
#verbal{
    display : none;
    position: relative;
    top: 40%;
    margin: 0 auto;
    width: 60vh;
    z-index:5;
    color: white;
    padding: 0;

}
.verbal{
    font-size: 7vh;
}
#dactylo{
    display : none;
    position: relative;
    top: 30%;
    margin: 0 auto;
    width: 100vh;
    z-index:5;
    color: white;
    padding: 0;

}
#dactere{
    display : none;
    position: relative;
    top: 25%;
    margin: 0 auto;
    width: 100vh;
    z-index:5;
    color: white;
    padding: 0;
}

.dactere{
    width:90%;
    text-align: center;
    margin: 0 auto;
    padding: 4vh 1vh;
    border: 1px solid white;
    border-radius: 1vh;
    background-color: orange;
}

@media(max-width: 768px){

    #dactere{
        width:95%;
        top:45%;
    }

    .dactere{
        width:90%;
        margin: 0 auto;
        padding: 4vh 1vh;
        border: 1px solid white;
        border-radius: 1vh;
        background-color: orange;

    }

}
#dactylo h1{
    margin: 0;
    padding: 0;
    text-align:center;
}
@media(max-width:768px){
    #dactylo{
        top: 20%;
        width:95%;
        margin:0 auto;
    }
}
.roum_ul{
    width:50%;
    float:left;
    text-align:center;
    margin: 0 0 2vh 0
}

#roum h2{
    font-size: 7vh;
    margin: 0;
}
#auditif{
    display : none;
    position: relative;
    top: 25%;
    margin: 0 auto;
    width: 60vh;
    z-index:5;
    color: white;
    padding: 0;
}

#auditif img{
    width: 20vh;
    margin: 2vh 0 4vh 0;
}


#auditif h1{
    margin: 0;padding:0;
    font-size: 15vh;
    font-family: 300;
    font-family: 'Baloo Bhaina';

}

@media(max-width:768px){
    #roum{
        top: 30%;
        width: 100%;
    }

    .roum_ul{
        font-size: 3vh;
        margin: 0 0 5vh 0
    }

    #roum h2{
        font-size: 7vh;
        margin: 0 0 0 0;
        line-height: 15vh;
    }

    
#auditif h1{
    margin: 0;padding:0;
    font-size: 10vh;
    font-family: 300;
    font-family: 'Baloo Bhaina';

}
}
#roum h1,p{

    text-align: center;
    color: white;
    margin: 0;
    padding: 0;

}


#verbal
 b{
    font-size: 10vh;
}

#verbal button{
    cursor: pointer;
    width: 20vh;
    height: 7vh;
    border-radius: 4vh;
    font-weight: 3.5vh;
    margin: 5vh 2vh;
    border:none;
    background-color: orange;
    color: white;
}
#roum b{
    font-size: 10vh;
}

#roum button{
    cursor: pointer;
    width: 20vh;
    height: 7vh;
    border-radius: 4vh;
    font-weight: 3.5vh;
    margin: 5vh 2vh;
    border:none;
    background-color: orange;
    color: white;
}

.time{
    font-family: 'Baloo Bhaina';
    font-weight: 300;
    width: 15vh;
    height: 5vh;
    margin: 0 auto;
    text-align: center;
    color: white;
    font-size: 3vh;
}

.dact{
    padding: 3vh;
    background-color: white;
    border-radius: 1vh;
    width: auto;
    height: 30vh;
    margin: 0 0 3vh;
    cursor: text;
    box-shadow: 1px 1px 12px #555;
}

.dact span{
    position: static;
    float: left;
}
.top{
    width: 60vh;
    height: 5vh;
    text-align: center;
    color:white;
    margin : 2vh auto;
}

@media(max-width: 768px){
    .top{
        width:100%;
        margin: 2vh 0;
        padding:0;
    }

    #roum b{
        font-size:10vh;
        margin:0;
        padding:0;
    }

    #roum button{
        width: 45%;
        height: 7vh;
        margin: 15vh 1vh;
        border:none;
        border-radius:7vh;
        font-size:2vh;
        font-family: 'Baloo Bhaina';
        font-weight: 300;
    }
    #auditif{
        top:25%;
        width:95%;

    }
    #auditif img{
        margin: 2vh 0 5vh 0;
    }
}

.width{
    width: 40%;
}

#sign{
    width: 30%;
    margin: 0 auto;
    position:relative;
    top: 15vh;
}

#sign p{
    color: #aaa;
}

#sign h1{
    font-size: 3vh;
}
@media(max-width:768px){
    #sign{
        width: 95%;
    }
    .width{
        width: 50%;
        font-size:3vh;
    }
}

.users{
    position: relative;
    width: 65%;
    height: 100%;
    margin: 0vh auto;
    padding: 2vh;
    top: 0vh;
    z-index: 5;

}

@media(max-width:768px){
    .users{
        width: 95%;
        padding: 0;
        top: 5vh;
    }
}
.button {
    float: none;
    position : relative;
    font-size: 2vh;

}

.stats{
    width: 100%;
    height: 15vh;
    background-color:  dodgerblue;
}

.getUser{
        margin: 0;
        padding: 10vh 0  0 0;
        width: 100%;
        height: 60vh;
        position: fixed;
        top: 0;
        background-color: #26C4EC;
}

.user_dash ul{
    margin: 0vh auto 5vh 0;
    padding: 0;
    list-style-type: none;
    height: 10vh;
}

.user_dash ul li{
    margin: 2vh 0 ;
    padding: 1vh;
    display: inline-block;
    text-align: center;
}


.user_dash h1{
    margin: 0;
    padding:0;
    font-size: 6vh;
    font-family: 'Baloo Bhaina';
    color: white;
}

.user_dash h2{
    margin: 0;
    padding:0;
    font-size: 3vh;
    font-family: 'Baloo Bhaina';
    color: dodgerblue;
}

@media(max-width:768px){

    .user_dash ul li{
        margin: 0;padding:0;
    }
    .user_dash h1{
        margin: 0 1vh;padding:0;
        font-size: 5vh;
    }

    .user_dash h2{
        margin: 0;padding:0;
        font-size: 4vh;
    }

}
.gePoint{
    width: 100%;
    text-align: center;
    margin: 0 auto;
    height: auto;
    border-top: 2vh solid #26C4EC;
    background-color: white;
    padding: 2vh 0;
    box-shadow: 1px 1px 12px #aaa;
    border-radius:1vh;
}
.gePoint h1{
    margin : 0;
    color: #26C4EC;
}
.gePoint h2{
    margin : 0;
    color: #26C4EC;
}

.gePoint b{
    color: #aaa;
    font-size: 10vh;
    font-family: sans-serif;
    font-weight: 300;
    margin: 5vh 0;
}
.gePoint a{
    font-size: 2h;
    color: dodgerblue;

}

.chart_all{
    width: 100%;
    height: auto;
    box-shadow: 1px 1px 12px #aaa;
    margin: 3vh 0 ;
    padding: 0;
    background-color: white;
    padding: 2vh 0;
    border-radius: 1vh;
}
.chart_all h1{
    text-align: center;
    font-family: 'Baloo Bhaina';
    font-size: 3vh;
    font-weight: 300;
    margin: 0vh 0 0 0 ;
}
.Activiter{
    width: 100%;
    height: auto;
    margin: 3vh 0;
    padding:2vh 0;
    background-color: white;
    box-shadow: 1px 1px 12px #aaa;
    border-radius: 1vh;
}

.Activiter h1{
    text-align: center;
    font-family: 'Baloo Bhaina';
    font-size: 3vh;
    font-weight: 300;
    text-align : center;
}

.img_dasboard{
    width: 6vh;
    height : 6vh;
    margin: -10px;
}

.ul_activiter{
    margin: 1vh 0;
    padding: 0;
    width:100%;
}

.ul_activiter ul{
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.ul_activiter ul li{
    padding: 2vh;
    display: inline-block;
    width: 24%;
    text-align: center;
}
.ul_activiter ul li:nth-of-type(1){
    width: 10%;
}

.ul_activiter ul li:nth-of-type(2){
    width:30%;
    font-size: 2vh;
    padding:0;
    text-align: center;
}
.ul_activiter ul li:nth-of-type(3){
    width: 35%;
    font-weight: 300;
    font-size: 2vh;
    color: #aaa;

  }
@media(max-width: 768px){

    .ul_activiter ul li:nth-of-type(1){
        width: 16%;
    }

    .ul_activiter ul li:nth-of-type(2){
        width: 25%;
        font-size: 2vh;
        padding:0;
        text-align: center;
    }

    .ul_activiter ul li:nth-of-type(3){
        width: 30%;
        font-weight: 300;
        font-size: 1.5vh;
        color: #aaa;
        margin: 0;
        padding: 0;
    }

    .ul_activiter ul li:nth-of-type(4){
        width: 25%;
        margin: 0;
        padding: 0;
        font-size: 2.5vh;
        text-align: center;
    }

 }

#btn_start{
    padding: 1.5vh 0;
    width: 30vh;
    height: 7vh;
    margin:5vh 0;
    background-color: orange;
    border:none;
    color:white;
    font-size:3.5vh;
    font-family:'Baloo Bhaina';
    border-radius: 5vh;
    box-shadow: 0 4px 2px -2px gray;
}
#btn_add{
     width: 40vh;
    height: 5vh;
    margin:0vh 0;
    background-color: orange;
    border:none;
    color:white;
    font-size:3.5vh;
    font-family:'Baloo Bhaina';
    border-radius: 5vh;
}
#com{
    width: 40vh;
    height: 6vh;
    margin:7vh 0;
    background-color: rgba(255, 166, 0, 0.514);
    border:none;
    color:white;
    font-size:3.5vh;
    font-family:'Baloo Bhaina';
    border-radius: 5vh;
}

#btn_start_test{
    width: 20vh;
    height: 6vh;
    margin:10vh 0;
    background-color: orange;
    border:none;
    color:white;
    font-size:3.5vh;
    font-family:'Baloo Bhaina';
    border-radius: 5vh;
}

#btn_start:active{
    transform: scale(0.98);
}

@media(max-width: 768px){
    #btn_start{
        width:80%
    }
}

#visuel{
    display : none;
    position: relative;
    top: 25%;
    margin: 0 auto;
    width: 55vh;
    height: 55vh;
    z-index:5;
    color: white;
    padding: 0;
}

#all_cage{
    width: 55vh;
    height: 55vh;
    margin:0 auto;
    padding:0;
}

@media(max-width:700px){
    #visuel{
        top: 30%;
        width: 100%;
        margin: 0 0 0 0.5vh;
    }

    .cage{
        width : 14vh;
        height: 14vh;
        margin: 0 1vh 1vh 0;
    }

    #all_cage{
        width:45vh;
        height: auto;
        margin:0 auto;
        padding:0;
    }
}

.user_dash{
    width: 60%;
    position: relative;
    top: 20%;
    margin: 0 auto;
    height: auto;
    color: white;
}
.user_dash a{
    color: white;
}

@media(max-width: 768px){
    .user_dash{
        width: 95%;
    }
}

#btn-response{
    position: fixed;
    left: 2.5vh;
    top: 2.5vh;
    width: 12vh;
    height: 8h;
    display: none;
}


#btn-response span{
    display: block;
    width: 50%;
    height: 1vh;
    background-color: black;
    margin: 1vh 0 1vh 0;
    border-radius: 10vh;
}

@media(max-width: 768px){
    #btn-response{
        display: block;
    }
}

#sidebar{
    width:80%;
    height: 100%;
    position: fixed;
    background-color: white;
    overflow:hidden;
    top: 0;
    left: -80%;
    z-index: 15;
    box-shadow: 1px 1px 12px #aaa;


}

#sidebar li{
    list-style-type: none;
    width: 100%;
    height: 10vh;
    padding: 0 2vh;
    border-bottom: 1px solid #aaa;
    line-height: 10vh;
}

#sidebar li a{
    display: block;
    width: 100%;
    height: 10vh;
    color: #26C4EC;
    font-size: 3vh;
}
#sidebar li a:active{
    text-decoration: none;
    background-color: #aaa;
    color: white;
}
.openSidebar{
    animation: openSidebar 1600ms;
}

@keyframes openSidebar{
    0%{
        left: -80%;
    }
    100%{
        left: 0%;
    }
}
.close{
    animation: closeSidebar 1600ms;

}

@keyframes closeSidebar{
    0%{
        left: 00%;
    }
    100%{
        left: -80%;
    }
}
#closeSide{
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.493);
    position: fixed;
    top:0;
    left: 0;
    z-index: 10;
    display: none;
}

#err_login{
    width: 100%;
    height: auto;
    padding:2vh 1vh 0 1.5vh;
    text-align: center;
    border-bottom: 1vh solid red;
    background-color: rgba(255, 94, 0, 0.24);
    border-radius: 1vh 1vh 0 0;
    margin: 1vh 0;
    line-height: 4vh;
    color: black;
    display: none;
    transition:600ms;
}

.head_login{
    width: 100%;
    height: 15vh;
}

.btn_login{
    width: 98%;
    height: 6vh;
    text-align: center;
    color:red;
    line-height: 0;
    font-family: 'Baloo Bhaina';
}

.btn_facebook{
    width: 98%;
    height: 6vh;
    color: white;
    background-color: rgb(59, 91, 152);
    border: none;
    margin: 1vh 0;
}

.login_li{
    width: 50%;
    display: inline-block;
    margin: 0;
}

.container_r{
    height: 25vh;
    width: 60%;
    margin: 5vh auto 50vh;
}
.container_ro{
    width:50%;
    float: left;

}
.container_ro h1{
    margin: 3vh 0vh;
}
.container_ro a{
    margin: 10vh 0;
    font-size:3vh
}

.container_ro b{
    color: black;
    font-size: 3vh;
    font-family: 'Baloo Bhaina';
    font-weight: 300;
    margin: 0 auto;
    width: 10%;
    color: #aaa;
}

@media(max-width:768px){
    .container_r{
        width: 95%;
    }

    .container_ro{
        width: 100%;
    }

    .form-control{
        width: 100%;
        height:9vh;
    }
    .btn{
        width: 100%;
        height:9vh;
    }
}
.btn{
     width: 100%;
}

#body{
    margin:0;
    padding: 0;
    width: 100%;
    height: auto;
    position: absolute;
    top:0;
}

#paralax{
    width: 100%;
    position: absolute;
    top: 20vh;
}

#chart{
    height: 500vh;
}

#uop{
    text-align: center;
    font-size: 3vh;
    font-family: 'Baloo Bhaina';
    font-weight: 300;
    color: #aaa;
    margin: 0;
}

#bottom{
    width: 100%;
    margin:0vh 0;
    padding: 0 1vh;
}
#bottom b{
    color: #aaa;
}

#bottom ul{
    margin:0;
    padding: 3vh 0 0 0;
    list-style-type: none;
}

#licenec{
    text-align: center;
    position:relative;
    top: 10vh;
    width:70%;
    margin: 0 auto;
    text-align:start;
}

#licenec p{
    font-size: 3vh;
}

.img_span{
    width: 2vh;
    height:2vh;
    margin : 0.2vh 0.2vh;
}

span{
    height: 0.5vh;
    margin: 0;
    padding: 0;
}

#olll{
    width: 100%;
    height: 20vh;

}
#loader{width: 20vh;height: 10vh;margin: 0 auto;}

#loader img{width:100%;height: 10vh;}

#result_test{
    width:100%;
    margin: 0;
    padding: 0;
}

#result_test h1{
    margin: 0;
    padding:0;text-align: center;
}

#btn_restart_test{
    display: block;
    width:60%;height: 7vh;
    background-color: orange;line-height: 7vh;
    border-radius: 4vh;
    color:white;
    text-decoration: none;
    margin:1vh 0;
}
button:hover{
    transform: scale(0.99);
}
button:active{
    transform: scale(1);
}
#btn_restart_test:hover{
    transform: scale(0.99);
}
#btn_restart_test:active{
    transform: scale(1);
}

#share_fb{
    width:35vh;
    height: 7vh;
    border-radius: 5vh;
    border: none;
    background-color: rgb(60,90,153);
    color: white;
}
#share_fb img{
    width:1.5vh;
    margin:0 1vh;
}
#enter_iv{
    width:70%;
    margin: auto;
}

#enter_iv b{
    color: #aaa;
}

#resll{
    display: none;
}

#resll img{
    width:50%;
    height:10%;
}


#longout{
    width:100%;
    margin:0 auto;
    padding:0;
    position: relative;
    top: 20vh;
}

#ress{
    display:none;
color: white
}
 
#btn_scroll{
    width: 10vh;
    height: 10vh;
    border-radius: 10vh;
    transition: 600ms;
    cursor: pointer;
    position: relative;
    top:10vh;
    background-color: orange;
    box-shadow: 1px 1px 3px #555;
}
#btn_scroll:active{
    width: 7vh;
    height: 7vh;
    border-radius: 7vh;
    top:11vh;

}

#btn_scroll:active img{
    width: 7.5vh;
    height: 3.5vh;
    top: 3.5vh;
}

#btn_scroll img{
    width: 8vh;
    height: 4vh;
    position: relative;
    top: 3.5vh;
} 

.fa-facebook-square{
    width: 5.5vh;
    margin: 0 1vh 0 -10.5vh;
}

@media(max-width:768px){
        
    .fa-facebook-square{
        width: 5.5vh;
        margin: 0 1.5vh 0 -14vh;
    }

}

#verification{
    width: 40%;
    height: auto;
    position: relative;
    top: 15vh;
    margin: 0 auto;
}

#verification b{
    color: #aaa;
}

@media(max-width:768px){
    #verification{
        width: 95%;
    }
}

#verification img{
    width: 20vh;
}

#scroll{
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    scroll-behavior: smooth;
}

#resert_password{
    width: 40%;
    height: auto;
    position: relative;
    margin: 0 auto;
    top: 15vh;
    
}

@media(max-width:768px){
    #resert_password{
        width: 95%;
    }
}

#resert_password img{
    width: 20vh;
    margin: 3vh 0 0 0;
}
 
#resert_password b{
    color: #aaa;
}

.vie{
    position: relative;
    top: 20vh;
    margin: 0 auto;
    color: white;
    font-size: 2vh;
}

#confidentialiter{
    width: 100%;
    margin: 0 auto;
    height: auto;
    position: relative;
    top: 15vh;
}
 
.confi_title{
    width: 35vh;
    margin: 0 3vh;
    position: fixed;
    top: 15vh;

}


@media(max-width:768px){
.confi_title{
    display: none;
}
}
.confi_title ul{
    margin: 0;
    padding: 0;
}

.confi_title ul li{
    font-size: 1.5vh;
    margin: 0;
    padding: 0;
}



.conul_content{
    width: 60%;
    margin: 0 40vh;
    padding: 0;
    height: 80vh;
    overflow-y: scroll;
    scroll-behavior: smooth;
}


@media(max-width:768px){
    
.conul_content{
    width: 95%;
    margin: 0 auto;
    padding: 0;
    height: auto;
}}


.conul_el{
    height: auto;
    margin: 0vh 0vh 6vh 0;
}

.conul_el p{
    color: #aaa;
    text-align: start;
}

.conul_el h2{
    font-size: 3vh;
}

#opop{
    width: 100%;
    height: 50vh;
}

.litle{
    font-size: 1.5vh;
    padding: 0 0.5vh  ;
    position: relative;
    top: -0.4vh;
}

.fade-enter {
    opacity: 0.01;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
}

.fade-exit {
    opacity: 1;
}

.fade-exit.fade-exit-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
}